<template>
  <el-dialog
    id="ImportSKUFromGMDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
    destroy-on-close
  >
    <el-container>
      <div ref="flex_container" class="flex_container" v-loading="loading">
        <splitpanes class="default-theme" vertical style="height: 100%">
          <pane min-size="20" max-size="80" ref="pane1" size="25">
            <el-header class="header3" height="48"
              ><el-row type="flex" justify="start">
                <div>
                  <el-input
                    :placeholder="$t('Пошук')"
                    style="width:308px;"
                    prefix-icon="el-icon-search"
                    clearable
                    v-model="filterString"
                    ref="filter"
                    @keydown.esc.native="resetFilter($event, this)"
                  />
                </div>
              </el-row>
            </el-header>
            <div style="overflow-y:auto;overflow-x:hidden;border:1px solid #babfc7;background-color: white;">
              <el-tree
                :data="groups"
                default-expand-all
                ref="tree"
                highlight-current
                :filter-node-method="filterTreeNode"
                :expand-on-click-node="expandOnClick"
                empty-text="no data to display"
                @current-change="onTreeSelectNode"
              ></el-tree>
              <!-- </el-scrollbar> -->
            </div>
          </pane>
          <pane min-size="20" max-size="80" ref="pane2" size="75">
            <el-header class="header3" height="48"><el-row type="flex" justify="start"> </el-row> </el-header>
            <div>
              <ag-grid-vue
                style="width: 100%; height: 100%;"
                class="ag-theme-alpine"
                rowSelection="multiple"
                :defaultColDef="defaultColDef"
                :columnDefs="columns"
                :headerHeight="32"
                :rowData="sku"
                :gridOptions="gridOptions"
                :overlayNoRowsTemplate="noRowsToShowTemplate"
                @selection-changed="onGridSelectionChanged"
                @rowDoubleClicked="onGridDoubleClick"
                @sortChanged="onGridColumnsChange"
                @columnResized="onGridColumnsChange"
                @columnMoved="onGridColumnsChange"
                @displayedColumnsChanged="onGridColumnsChange"
                @gridReady="onGridReady"
                :modules="modules"
                :localeTextFunc="gridLocale"
                :sideBar="sideBar"
                :frameworkComponents="frameworkComponents"
                :enableBrowserTooltips="true"
                :pivotMode="false"
              >
              </ag-grid-vue>
            </div>
          </pane>
        </splitpanes>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button">{{ $t("Застосувати") }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <SKUInfo bus-event="import.gm.sku.forms.info" />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "ImportSKU",
  components: { AgGridVue, Splitpanes, Pane, Icon, TIButton, TSeparator, SKUInfo: () => import("@/components/forms/SKUInfo") },
  props: ["busEvent"],
  data() {
    return {
      modules: AllModules,
      frameworkComponents: null,
      dialogShow: false,
      expandOnClick: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      useLocalOrAxioma: true,
      model: {},
      operation: null,
      initModel: {},
      filterString: "",
      searchBy: "name",
      selectedSKU: null,
      keyUpTimer: null,
      gridApi: null,
      gridColumnApi: null,
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      defaultProps: {
        children: "children",
        label: "label",
      },
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      columns: [
        {
          headerName: $tt("Назва"),
          field: "sku",
          flex: 2,

          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "info-circle",
              type: "fas el-icon-fa-",
              color: "blue",
              iconClass: "icon-action",
              title: $tt("Інформаця про товар"),
              onClick: (params) => {
                this.showInfo(null, params.data);
              },
            },
          ],
        },
        {
          headerName: $tt("Виробник"),
          field: "maker",
          flex: 1,
        },
        {
          headerName: $tt("Маркетуюча організація"),
          field: "marketOrg",
          flex: 1,
        },
        {
          headerName: $tt("Рег. дата початок"),
          field: "regDateBegin",
          width: "200px",
        },
        {
          headerName: $tt("Рег. дата завершення"),
          field: "regDateEnd",
          width: "200px",
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  created() {
    bus.$on(this.busEvent + ".show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.target = (data && data.target) || "unknown";
        this.operation = (data && data.operation) || "create";
        this.filterString = "";
        this.dialogShow = true;
      }
    });
    bus.$on(this.busEvent + ".hide", (data) => {
      this.dialogShow = false;
    });
  },
  beforeMount() {
    this.frameworkComponents = { GridCellWithIcons };
  },
  computed: {
    groups() {
      return this.$store.getters["sku/groups"];
    },
    sku() {
      return this.$store.getters["sku/store"];
    },
    loading() {
      return this.$store.getters["sku/wait"]();
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 124 + 50;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container.style.width = `100%`;
      this.$refs.flex_container.children[0].children[0].children[1].style.height = `${containerHeight - 50}px`;
      this.$refs.flex_container.children[0].children[2].children[1].style.height = `${containerHeight - 48}px`;
    },
    filterString(value) {
      this.$refs.tree.filter(value);
    },
  },

  methods: {
    showInfo(event, data) {
      if (data) {
        bus.$emit("import.gm.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: data.drugsId },
        });
      } else if (this.selectedSKU && this.selectedSKU.length) {
        bus.$emit("import.gm.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: this.selectedSKU[0].drugsId },
        });
      }
    },
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("ImportSKUFromGMGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    onOpen() {
      this.observeHeight();
    },
    onOpened() {
      this.$store.dispatch("sku/init");
      this.getGroups();
    },
    onClose() {
      this.$store.dispatch("main/cancelAllRequests");
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.selectedSKU && this.selectedSKU.length) {
        this.onClose();
        this.$emit("find-commit", { target: this.target, result: this.selectedSKU, operation: this.operation });
      } else this.$message.error($tt("Будь ласка, додайте один або декілька рядків в нижню таблицю!"));
    },
    getGroups() {
      this.$store
        .dispatch("sku/get_groups_list")
        .then((data) => {})
        .catch((err) => {
          this.$message.error(parseError(err.data ? err.data.msg : err.message));
        })
        .finally((data) => {});
    },
    getSKU(node) {
      this.$store
        .dispatch("sku/get_list_by_node", { node, lang: this.$store.getters["main/userSettings"]("userSettings").defaultCatalogLang })
        .then((data) => {})
        .catch((err) => {
          this.$message.error(parseError(err.data ? err.data.msg : err.message));
        })
        .finally((data) => {});
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedSKU = rows;
      } else {
        this.selectedSKU = null;
      }
    },
    onGridDoubleClick() {
      this.onDel();
    },
    onFormEnter() {
      this.onAdd();
    },
    filterTreeNode(value, data) {
      if (!value) return true;
      if (data.id == 0) return true;
      return data.label.toLowerCase().search(value.toLowerCase()) !== -1;
    },
    onTreeSelectNode(node) {
      this.getSKU(node);
    },
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { ImportSKUFromGMGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
  },
};
</script>

<style lang="scss" scope>
#ImportSKUFromGMDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#ImportSKUFromGMDialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}

#ImportSKUFromGMDialog .el-tree--highlight-current .el-tree-node.is-current > .el-tree-node__content {
  background-color: var(--ag-selected-row-background-color, rgba(3, 109, 12, 0.3));
}
</style>
